<template>
  <span>
    <input
      ref="costUploader"
      id="cost-uploader"
      type="file"
      @change="onChange"
    />

    <span @click="onClick">
      <slot />
    </span>
  </span>
</template>

<script>
import { upload } from "@/api/shared-cost";
export default {
  props: {
    year: {
      type: String,
    },
  },

  methods: {
    onClick() {
      this.$refs.costUploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.$emit("setLoading", true);
      const hide = this.$message.loading("上传中...", 0);

      upload(
        {
          year: this.year,
        },
        data
      )
        .then((res) => {
          console.log("upload res", res);
          // if (res !== "success") {
          //   // this.$error({
          //   //   title: "提示",
          //   //   content: res,
          //   // });
          // } else {
          //   this.$message.success("上传成功！");
          // }
        })
        .finally(() => {
          this.$emit("setLoading", false);
          hide();
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#cost-uploader {
  display: none;
}
</style>
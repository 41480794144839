

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline">
            <a-form-model-item>
              <a-date-picker
                mode="year"
                placeholder="年份"
                @panelChange="panelChange"
                :value="form.year"
                format="YYYY"
                :open="open"
                @openChange="openChange"
                style="width: 80px"
                :allowClear="false"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="部门"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 150px"
                v-model="form.selectedDepartment"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.uniqueName"
                  :value="item.uniqueName"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-button @click="reset">重置</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <Uploader
                :year="form.year.format('YYYY')"
                :loading="uploadLoading"
                @setLoading="setUploadLoading"
              >
                <a-button :loading="uploadLoading">
                  导入劳务成本原始数据</a-button
                >
              </Uploader>

              <a-button @click="downloadTemplate" type="primary">
                下载模板</a-button
              >

              <a-button @click="download"> 导出</a-button>
              <a-button
                @click="getCertificate"
                :loading="downloadLoading"
                icon="vertical-align-bottom"
                type="primary"
                >导出凭证
              </a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="filteredList"
        :loading="loading"
        :pagination="false"
        :scroll="{ x: 3000 }"
      >
        <a-table-column
          title="部门名称"
          data-index="deptName"
          ellipsis="true"
          width="140px"
          fixed="left"
        >
        </a-table-column>

        <a-table-column
          v-for="item in columns"
          :key="item"
          :title="item"
          width="100px"
          align="right"
        >
          <template slot-scope="text">
            <span :style="moneyColor(text[item])">
              {{ moneyFilter(text[item]) }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="100px" fixed="right">
          <template slot-scope="text">
            <router-link
              v-if="$getPermission($route.path + '/project-detail')"
              :to="
                $route.path +
                `/project-detail?deptId=${text.deptId}&year=${form.year.format(
                  'YYYY'
                )}`
              "
            >
              项目分摊
            </router-link>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import {
  fetchList,
  fetchTemplate,
  downloadCertificate,
} from "@/api/shared-cost";
import Uploader from "./components/uploader.vue";
import { saveAs } from "file-saver";
import downloadFile from "@/utils/xlsx";
import moment from "moment";

export default {
  name: "sharedCost",

  mixins: [organization, watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {
        year: moment(),
      },
      open: false,
      loading: false,
      list: [],
      columns: [],

      uploadLoading: false,

      downloadLoading: false, // 凭证导出 loading
    };
  },

  computed: {
    filteredList() {
      if (this.form.selectedDepartment) {
        return this.list.filter(
          (item) => item.deptName === this.form.selectedDepartment
        );
      } else {
        return this.list;
      }
    },
  },

  activated() {
    this.getList();
  },

  methods: {
    // 年份选择器
    panelChange(value) {
      this.form.year = value;
      this.open = false;
      this.getList();
    },
    // 年份选择器面板显示隐藏
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.loading = true;
      fetchList({
        year: this.form.year.format("YYYY"),
      })
        .then((res) => {
          if (Array.isArray(res)) {
            const filteredList = res.map((item, index) => {
              const obj = {
                deptId: item.deptId,
                deptName: item.deptName,
                // productUnit: item.productUnit,
              };
              if (Array.isArray(item.subjectList)) {
                if (index === 0) {
                  this.columns = item.subjectList.map(
                    (element) => element.subjectCode
                  );
                }

                item.subjectList.forEach((element) => {
                  obj[element.subjectCode] = element.cost;
                });
              }

              return obj;
            });
            this.list = Object.freeze(filteredList);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.form = {
        year: moment(),
      };
      this.getList();
    },

    moneyFilter(money) {
      if (typeof money !== "number" || !money) {
        return 0;
      }

      const moneyStr = String(money);
      const arr = moneyStr.split(".");
      if (arr.length > 1) {
        return this.separateMoney(arr[0]) + "." + arr[1];
      } else {
        return this.separateMoney(moneyStr);
      }
    },

    separateMoney(moneyStr) {
      let arr = moneyStr.split("").reverse();

      let result = [];

      for (let i = 0; i < arr.length; i++) {
        if (i && i % 3 === 0) {
          result.push(",");
        }
        result.push(arr[i]);
      }

      return result.reverse().join("");
    },

    moneyColor(money) {
      if (parseFloat(money) < 0) {
        return {
          color: "#f00",
        };
      } else {
        return {
          color: "#1890ff",
        };
      }
    },

    setUploadLoading(payload) {
      this.uploadLoading = payload;
    },

    download() {
      downloadFile(this.list, "公摊成本");
    },

    getCertificate() {
      this.downloadLoading = true;
      downloadCertificate({
        date: this.form.year.format("YYYY-MM-DD"),
      })
        .then((blob) => {
          saveAs(blob, "公摊成本凭证.xlsx");
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },

    // 下载模板
    downloadTemplate() {
      fetchTemplate()
        .then((blob) => {
          saveAs(blob, "公摊成本模板");
        })
        .catch((err) => {
          console.log("err", err);
          // this.$message.error("导出出错！");
        });
    },
  },
};
</script>
